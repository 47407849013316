import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Modal,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import {tokens} from "../../theme";

import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  ArrowDropDownCircleOutlined,
  AccountCircle,
  ExitToApp,
} from "@mui/icons-material";
import { environment } from "../../environment";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const [adminId, setAdminId] = useState("");
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({
    email: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
      }
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOldPassword("");
    setNewPassword("");
  };

  const handleChangePassword = async () => {
    try {
      console.log(adminId, "-------");

      const res = await axios.put(
        environment.apiUrl + `/admin/ChangePassword/${adminId}`,
        {
          oldPassword,
          newPassword,
        }
      );

      console.log("Changing password", res);
      // If successful, show a success message and close the modal
      Swal.fire("Success", "Password changed successfully", "success");
      handleCloseModal();
    } catch (error) {
      console.error("Error changing password:", error);
      Swal.fire("Error", "Failed to change password", "error");
      handleCloseModal();
    }
  };
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const adminIdFromToken = decodedToken.AdminId;
        setAdminId(adminIdFromToken);
        console.log("Admin ID:", adminIdFromToken);
      } catch (error) {
        console.error("Invalid token", error);
      }
    } else {
      console.error("No token found in localStorage");
    }
  }, [token]);

  useEffect(() => {
    const fetchAdminData = async () => {
      if (adminId) {
        try {
          const response = await axios.get(
            `${environment.apiUrl}/admin/getAdmin`
          );
          setAdminData({
            email: response.data[0].email,
          });
          console.log(response.data, "----");
        } catch (error) {
          console.error("Error fetching admin data:", error);
        }
      }
    };

    fetchAdminData();
  }, [adminId]);

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      backgroundColor={colors.primary[400]}
      p={0}
    >
      {/* ... (rest of the component remains the same) ... */}

      <Box>
        <IconButton
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginRight="20px"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              m: "10px",
              alignItems: "center",
            }}
            onClick={handleClick}
          >
            <Avatar src="" alt="admin profile" />
            <div style={{ width: "3px" }} />
            <ArrowDropDownCircleOutlined />
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModal} style={{ fontSize: "larger" }}>
              <IconButton>
                <AccountCircle />
              </IconButton>
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout} style={{ fontSize: "larger" }}>
              <IconButton>
                <ExitToApp />
              </IconButton>
              Logout
            </MenuItem>
          </Menu>
        </IconButton>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="change-password-modal"
        aria-describedby="modal-to-change-user-password"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {/* <Typography variant="h6" component="h2" gutterBottom>
            Change Password
          </Typography> */}
          <Typography variant="h6" component="h2" gutterBottom>
            {adminData.email}
          </Typography>
          <TextField
            fullWidth
            type="password"
            label="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleChangePassword} variant="contained">
              Change Password
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Topbar;
